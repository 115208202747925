import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-huntington-beach-california.png'
import image0 from "../../images/cities/scale-model-of-statue-\"ben-carlson\"-in-huntington-beach-california.png"
import image1 from "../../images/cities/scale-model-of-world's-largest-surfboard-in-huntington-beach-california.png"
import image2 from "../../images/cities/scale-model-of-walk,-wine-&-dine-in-huntington-beach-california.png"
import image3 from "../../images/cities/scale-model-of-heritage-museum-of-orange-county-in-huntington-beach-california.png"
import image4 from "../../images/cities/scale-model-of-newport-beach-pier-in-huntington-beach-california.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Huntington Beach'
            state='California'
            image={image}
            lat='33.6594835'
            lon='-117.99880259999998'
            attractions={ [{"name": "Statue \"Ben Carlson\"", "vicinity": "101-123 Court St, Newport Beach", "types": ["point_of_interest", "establishment"], "lat": 33.6080831, "lng": -117.92868049999998}, {"name": "World's Largest Surfboard", "vicinity": "410-498 Olive Ave, Huntington Beach", "types": ["point_of_interest", "establishment"], "lat": 33.65920980000001, "lng": -118.0008062}, {"name": "Walk, Wine & Dine", "vicinity": "101 Main St, Huntington Beach", "types": ["point_of_interest", "establishment"], "lat": 33.6576447, "lng": -118.00197049999997}, {"name": "Heritage Museum of Orange County", "vicinity": "3101 W Harvard St, Santa Ana", "types": ["museum", "park", "point_of_interest", "establishment"], "lat": 33.7207429, "lng": -117.9106923}, {"name": "Newport Beach Pier", "vicinity": "70 Newport Pier, Newport Beach", "types": ["point_of_interest", "establishment"], "lat": 33.6074608, "lng": -117.9288133}] }
            attractionImages={ {"Statue \"Ben Carlson\"":image0,"World's Largest Surfboard":image1,"Walk, Wine & Dine":image2,"Heritage Museum of Orange County":image3,"Newport Beach Pier":image4,} }
       />);
  }
}